import React, { useState } from 'react';
// import Geocode from "react-geocode";
import { compose, withProps, lifecycle } from 'recompose';
import { GoogleMap, Marker , withScriptjs, withGoogleMap} from "react-google-maps"
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";

import CustomInput from '../CustomFields/CustomInput.jsx';

import { 
  Box,
} from '@material-ui/core';

import apiUtil from '../../api/apiUtil.jsx';


// Geocode.setApiKey(apiUtil.getGoogleMapApiKey());


const DEF_CENTER = { lat: 1.352083, lng: 103.819836 };


const CustomGoogleMap = compose(
    withProps({
        googleMapURL: apiUtil.getGoogleMapUrl(),
        loadingElement: <div style={{ width: '100%', height: '100%' }} />,
        containerElement: <div style={{ width: '100%', height:'100%', position: 'fixed' }} />,
        mapElement: <div style={{ width: '100%', height: `100%`}} />
    }),
    lifecycle({
        componentWillMount() {
          const refs = {}
          let center = (this.props.center) ? this.props.center : DEF_CENTER;

          this.setState({
            getRefs: () => {
              return refs;
            },
            getCenter: () => {
              return center;
            },
            onMapMounted: ref => {
              refs.map = ref;
            },
            onSearchBoxMounted: ref => {
              refs.searchBox = ref;
            },
            onMarkerMounted: ref => {
              refs.marker = ref;
            },
            onDragEnd: ref => {
              let position = refs.marker.getPosition();
              let pos = {
                lat: position.lat(),
                lng: position.lng(),
              };

              // Geocode.fromLatLng(pos.lat, pos.lng).then(
              //   response => {
              //     const address = response.results[0].formatted_address;
              //     console.log("Geocode address", address);
              //   },
              //   error => {
              //     console.error(error);
              //   }
              // );

              if(this.props.onDragEnd){
                this.props.onDragEnd(pos);
              }
            },
            onPlacesChanged: () => {
              let places = refs.searchBox.getPlaces();
              
              center = ((places && places[0]) ? { lat: places[0].geometry.location.lat(), lng: places[0].geometry.location.lng() } : null);

              if(this.props.onPlacesChanged){
                this.props.onPlacesChanged((places && places.length > 0) ? places[0] : null);
              }
            },
          })
        },
      }),
    withScriptjs,
    withGoogleMap
  )(props => {
    const [className] = useState(props.className ? props.className : '');
    const [center, setCenter] = useState(props.center ? props.center : DEF_CENTER);
    const [driver, setDriver] = useState(props.driver ? props.driver : "Driver");
    const [stepAddress, setStepAddress] = useState(props.step_address ? props.step_address : []);
    const [placeholder] = useState(props.placeholder ? props.placeholder : '');
    const [value, setValue] = useState(props.value ? props.value : '');
    const [startAdornment] = useState(props.startAdornment ? props.startAdornment : null);
    const [endAdornment] = useState(props.endAdornment ? props.endAdornment : null);
    const [defaultZoom] = useState(props.defaultZoom ? props.defaultZoom : 16);
    const [showSearch] = useState((props.showSearch === true || props.showSearch === false) ? props.showSearch : false);

    React.useEffect(() => {
      setCenter(props.center ? (((props.center.lat !== 0) || (props.center.lng !== 0)) ? props.center : DEF_CENTER) : DEF_CENTER);
      setDriver(props.driver? props.driver: '')
      setStepAddress(props.step_address ? props.step_address : [])
    }, [ props.center , props.driver, props.step_address]);

    const googleMap = () => {
      return <div>
        <GoogleMap
          ref={props.onMapMounted}
          onCenterChanged={props.onCenterChanged}
          defaultZoom={defaultZoom}
          defaultCenter={DEF_CENTER}
          defaultOptions={{
            disableDefaultUI: true,
            disableDoubleClickZoom: true,
            rotateControl: false,
            gestureHandling: "cooperative",
          }}
          center={center}
        >
          {searchBox()}
          {(center) && <Marker 
            ref={props.onMarkerMounted}
            onDragEnd={props.onDragEnd}
            label={driver}
            // draggable={true}
            position={center}
          /> 
          }
          {showAllMarkers()}
          
          
        </GoogleMap>
      </div>
    }
    
    const showAllMarkers = () => {
      return stepAddress.map((item, i) => {
        let position = (item.latitude && item.longitude) ? { lat: item.latitude, lng: item.longitude } : null;
        let label = item.order_sequence ? "Step "+item.order_sequence : "Step "+i;
        if(position) {
          return <Marker 
                  key={i}
                  label={label}
                  position={position}
                  draggable={false}
                  
              ></Marker>
        }
      })

    }
    const searchBox = () => {
      if(showSearch){
        return <div>
          <SearchBox
            ref={props.onSearchBoxMounted}
            onPlacesChanged={props.onPlacesChanged}
            controlPosition={1}
          >
              <Box p={2}>
                <CustomInput
                  className={"custom-google-search " + className}
                  value={value}
                  placeholder={placeholder}
                  startAdornment={startAdornment}
                  endAdornment={endAdornment}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                  onPressEnter={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                />
              </Box>
          </SearchBox>
        </div>
      }
    }

    return googleMap();
});


export default CustomGoogleMap;