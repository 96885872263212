
import './App.css';
// import './assets/scss/main.scss';
import {BrowserRouter, Routes, Route, Redirect} from 'react-router-dom';
import PageNotFound from './views/PageNotFound/PageNotFound.jsx'
import TrackMe from './views/TrackMe/TrackMe.jsx'
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/trackme" element={<TrackMe/>} />
          <Route path="*" element={<PageNotFound/>} />
        </Routes>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
